import InAppSpy from "inapp-spy";
import Bowser from "bowser";

if(sessionStorage.foundUser != "true") {
	window.onload = findUserType();
}

function findUserType() {
	const { isInApp, appKey, appName } = InAppSpy();
	const browser = Bowser.getParser(window.navigator.userAgent);
	const isApple = browser.is("iOS");

	if(isInApp) {
		//if the user is in an in-app browser
		document.getElementsByTagName("body")[0].style.overflow = "hidden";
		let inAppLink = document.querySelector("#inAppLink");
		inAppLink.setAttribute("data-app", appName);
		if(isApple) {
			inAppLink.href = window.location.href;
			inAppLink.innerHTML = "Open Browser (hold down and press open link)";
		} else {
			inAppLink.href = "intent:" + window.location.href + "#Intent;end";
			inAppLink.innerHTML = "Open Browser";
		}
		document.querySelector("#inAppBrowserBox").style.display = 'flex';
	} else {
		//do nothing
	}
sessionStorage.setItem("foundUser", "true");
}